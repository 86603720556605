
.nav_container{
display: flex;
align-items: center;
justify-content:space-between;
padding:20px 80px;
box-shadow:0 5px 15px  rgba(0,0,0,0.09);
border-bottom: 5px solid #CF0A0A;

}
.nav_bar{
    display:flex;
    align-items:center;
    justify-content:center;
   
}
.nav_bar li{
    list-style: none;
    padding: 0 20px;
    position:relative;
}
.nav_bar li a,
.nav_bar li span{
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: #000000;
    transition:0.3s ease-in-out;
}
.nav_bar li a:hover,
.nav_bar li a.active,
.nav_bar li span:hover,
.nav_bar li span.active{
    color:#CF0A0A;
    
}
.nav_bar li a:hover::after,
.nav_bar li a.active::after,
.nav_bar li span:hover::after,
.nav_bar li span.active::after{
    content:" ";
    width: 30%;
    height: 2px;
    background:#CF0A0A;
    position:absolute;
    bottom: -4px;
    left:20px;
    transition:0.3s ease-in-out;
}
#mobile{
    display:none;
}
#mobile i{
    font-size: 1.3rem;
    font-weight: 600;
}
@media screen and (max-width:769px){
    .nav_bar{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 110px;
        right: 5px;
       width: 40vh;
        height: 40vh;
        box-shadow:0 40px 60px rgba(0,0,0,0.1) ;
        padding:10px 0 0 10px;
        background:#000000;
        transition: 0.3s ease-in-out;
        z-index: 40;
        
    } 
    .nav_bar li{
        margin-bottom: 25px;
        
        
    }
    .nav_bar li a,
    .nav_bar li span{
        color:white
    }
    #mobile{
        display:block;
       
    }  
    #mobile i{
        font-size: 24px;
        cursor:pointer
    } 
    .nav_bar.active{
        right:-400px;
        
    }
}