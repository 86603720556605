* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.App {
  /*text-align: center;*/
 {/* margin: 1rem;*/}
  
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.aside, .hide-mobile, #breadcrumb{
        display:block;
    }
@media screen and (max-width:769px){
    .aside, .hide-mobile, #breadcrumb{
        display:none;
    }
}
.action_btn{
    padding: 4px;
    font-size: 15px;
}
.action_btn:hover{
    color:green;
    cursor: pointer;
}
.action_del{
    color:red;
}

.action_edit{
    color:blue
}
.tablefilter{
    
    display: inline-block;
    justify-content:space-between;
}
.rdt_Table{
   
    
    border:2px solid #e0e0e0;
}
.rdt_TableHeader{
   text-align: left;
    
       
}
header{
   padding: 0;
  
       
}